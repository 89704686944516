<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>集群</h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="text" icon="el-icon-plus" @click="addGroup">新增集群</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="tab-wrap">
      <el-row :gutter="20">
        <div v-if="total==0" style="text-align: center; padding: 20px;color: #999">暂无数据</div>
        <el-col :span="6" v-for="(item, index) in groupList" :key="index"  class="box-card">
          <el-card :body-style="{ padding: '0px' }">
            <el-image style="width: 100%; height: 180px;cursor: pointer" :src="item.pic" fit="fill" @click="goDetail(item.id)"></el-image>
            <div class="box-card-desc">
              <div class="box-title" @click="goDetail(item.id)">
                {{item.name}}
              </div>
              <div class="bottom clearfix">
                <el-link type="primary" class="button" @click="editGroup(item.id)">编辑</el-link>
                <el-link type="primary" class="button" @click="deleteGroup(item.id)">删除</el-link>
                {{item.manager}}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="pagenation">
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 50]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false">
      <hw-group-form :id="id" @closePop="closePop"></hw-group-form>
    </el-dialog>
  </div>
</template>

<script>
  import GroupForm from "../components/pages/GroupForm";

  export default {
    name: "Group",
    components: {
      hwGroupForm: GroupForm,
    },
    data() {
      return {
        groupList: [],
        addPop: false,
        popTitle: '新增集群',
        id: null,
        currentPage: 1,
        pageSize: 8,
        total: 0,
      }
    },
    created() {
      this.getListData();
    },
    methods: {
      getListData () {
        this.$get(this.$api.GetGroupList).then((res) => {
          this.groupList = res.data.list;
          this.total = res.data.total_count;
        });
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getListData()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getListData()
      },
      goDetail(id) {
        this.$router.push({name: 'groupdetail', query: {id: id}});
      },
      addGroup() {
        this.addPop = true;
        this.popTitle = '新增集群';
        this.id = null;
      },
      editGroup(id) {
        this.addPop = true;
        this.popTitle = '编辑集群';
        this.id = id;
      },
      closePop(param) {
        this.addPop = false;
        this.popTitle = '新增集群';
        if (param == 'refresh') {
          this.getListData();
        }
      },
      deleteGroup(id) {
        this.$confirm('此操作将删除此集群吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteGroup, {id: id}).then(() => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
            this.getListData();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
  }
</script>

<style scoped lang="less">
  .tab-wrap{
    padding: 15px;
  }
  .image{
    width: 100%;
    height: 120px;
  }
  .bottom{
    line-height: 32px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }
  .button{
    float: right;
    margin-left: 8px;
  }
  .pagenation{
    margin: 20px;
    text-align: center;
  }
  .box-card{
    margin-top: 10px;
    .box-card-desc{
      padding: 14px;
      .box-title{
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
</style>
